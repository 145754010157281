import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";

import { graphql } from "gatsby";

import "../css/app.css";

import OaiSync from "../components/OaiSync";
import {getViewModule} from "../util/router";

const RouterPage = ({ data, ...props }) => {
    const { strapiEntities: entity, allStrapiI18NLocale: { nodes: locales } } = data;
    const { pageContext: { files } } = props;
    const [strapiChildren, setStrapiChildren] = useState([]);
    const oaiRef = useRef();

    useEffect(() => {//quick, sync, cached version to avoid "flickering"
        if (oaiRef.current) {
            entity.strapiChildren = oaiRef.current.updateExhibitedStatusCached(entity.strapiChildren);
            oaiRef.current.updateExhibitedStatusCached([entity]);

            setStrapiChildren(entity.strapiChildren);
        }
    }, [setStrapiChildren, oaiRef]);

    useEffect(() => {//actual sync
        const updateChildren = async () => {
            entity.strapiChildren = await oaiRef.current.updateExhibitedStatus(entity.strapiChildren);
            await oaiRef.current.updateExhibitedStatus([entity]);

            //entity.strapiChildren = await filteredEntities(entity.strapiChildren);
            setStrapiChildren(entity.strapiChildren);
        };
        if (oaiRef.current)
            updateChildren();
    }, [setStrapiChildren, oaiRef]);

    const { module } = entity;

    const selectedModule = module?.name;

    const viewSettings = _.find(entity.Components, { strapi_component: 'component.topbar' });


    const viewProps = {
        entity,
        selectedModule,
        files,
        locales,
        config: viewSettings || {},
        showBackbutton: entity.identifier !== 'root',
    }

    const ViewModule = getViewModule(selectedModule);

    return <OaiSync ref={oaiRef}>
        <ViewModule  {...props} {...viewProps} strapiChildren={strapiChildren} />
    </OaiSync>
};

export default RouterPage;

export const query = graphql`
    query ($entityId: String!, $locale: String!) {
        strapiEntities(identifier: {eq: $entityId}, locale: {eq: $locale}) {
            strapiId
            identifier
            title
            locale
            localizations { locale }
            shortDesc
            smallImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 150)
                    }
                }
            }
            largeImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
            strapiChildren {
                id
                title
                identifier
                locale
                smallImage {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 500)
                        }
                    }
                }
                Roles
            }
            Components
            Roles
            module {
                name
            }
        }
        allStrapiI18NLocale {
            nodes {
                name
                code
                isDefault
            }
        }
    }
`;




/*TODO - conditional module-loading confuses webpack/gatsby-offline
class RouterPage extends React.Component {
    state = {
        Module: null,
    };

    componentDidMount = async () => {
        if (this.props.data.strapiEntities.Module?.length) {
            const module = await import(`../modules/${this.props.data.strapiEntities.Module[0]?.strapi_component.split('.')[1]}`);
            this.setState({ Module: module.default });
        }
    };

    render = () => {
        const { Module } = this.state;
        let entity = this.props.data.strapiEntities;
        entity.config = JSON.parse(entity.configJSON);//TODO - remove if remains unused

        if (!entity.Module || !entity.Module[0]?.strapi_component) return <>No module selected.</>;
        else
            return (Module === null ? <></> : <Module showBackbutton={entity.identifier!=='root'} entity={entity} config={entity.Module[0] || {}} />);

    }
}*/
